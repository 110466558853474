import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MatDialog } from '@angular/material';
import { ConfirmationViewComponent } from './confirmation-view/confirmation-view.component';
import { ConfirmationService } from '../../services/confirmation.service';

@AutoUnsubscribe()
@Component({
  selector: 'yosite-confirmation',
  template: '<ng-content></ng-content>',
  providers: [MatDialog],
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.confirmationService.confirmation()
      .subscribe(confirmation =>
        this.dialog.open(
          ConfirmationViewComponent,
          { autoFocus: false, disableClose: true, minWidth: '38vw', data: confirmation }
        )
      );
  }

  ngOnDestroy(): void { }

}
