<mat-toolbar color="primary" class="header-toolbar p-4">
  <a routerLink="/portfolio">
    <mat-icon class="mr-2" aria-hidden="false" aria-label="Portfolio" fontSet="material-icons-outlined">shop</mat-icon> <span
      class="mr-5">portfolio</span>
  </a>

  <a routerLink="/designers">
    <mat-icon class="mr-2" aria-hidden="false" aria-label="Designers" fontSet="material-icons-outlined">brush</mat-icon> <span
      class="mr-5">designers</span>
  </a>

  <a routerLink="/contact">
    <mat-icon class="mr-2" aria-hidden="false" aria-label="Contact" fontSet="material-icons-outlined">phonelink_ring</mat-icon> <span
      class="mr-5">contact</span>
  </a>

</mat-toolbar>

<article class="main-content">
    <router-outlet></router-outlet>
</article>
