import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AlertService } from '../../services';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Alert } from '../../alert.model';
import { MatDialog, MatDialogRef, MatButtonModule } from '@angular/material';
import { AlertErrorViewComponent } from './alert-error-view/alert-error-view.component';

@AutoUnsubscribe()
@Component({
  selector: 'yosite-alert-error',
  template: '<ng-content></ng-content>',
  providers: [MatDialog],
})
export class AlertErrorComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.alertService.alertError$
      .subscribe(alert => this.dialog.open(AlertErrorViewComponent, { autoFocus: false, disableClose: true, minWidth: '38vw', data: alert }));
  }

  ngOnDestroy(): void { }

}
