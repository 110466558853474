<div alert-title-row>
    <h1 mat-dialog-title>
      {{data.title | translate}}
      <button mat-dialog-close class="close-text">
        <mat-icon>close</mat-icon>
      </button>
    </h1>
</div>

<div mat-dialog-content>
  <p *ngIf="data.message">{{ data.message | translate }}</p>
  <ng-container *ngIf="data.messages">
    <p *ngFor="let message of data.messages">{{ message | translate }}</p>
  </ng-container>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="accent">{{keys.ok_button | translate}}</button>
</div>
