import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AuthorizationService } from './shared/services/authorization.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  title = 'aritco-frontend';

  constructor(
    public authorizationService: AuthorizationService,
    private translateService: TranslateService
  ) {
    this.translateService.addLangs(['en-GB']);
    this.translateService.setDefaultLang('en-GB');
    this.translateService.use('en-GB');
  }

}
