import { LoginComponent } from './login';
import { LogoutComponent } from './logout';

export const entities = [
  LoginComponent,
  LogoutComponent
];

export * from './login';
export * from './logout';
