import { Component, OnInit } from '@angular/core';
import { UsersKeys } from '../../translation-keys.model';
import { AlertService } from '@root/src/app/shared/services';
import { AuthorizationService } from '@root/src/app/shared/services/authorization.service';
import { Router } from '@angular/router';

@Component({
  selector: 'yosite-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  keys: UsersKeys = new UsersKeys();

  constructor(
    private alertService: AlertService,
    private authorizationService: AuthorizationService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.authorizationService.logout();
    setTimeout(() => {
      this.router.navigate(['login']);
  }, 1000);
  }
}
