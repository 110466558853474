export class UsersKeys {
  login = {
    title: 'users.login.title',
    forgotPassword: 'users.login.forgot_password',
    rememberMe: 'users.login.remember_me',
    loginButton: 'users.login.login_button'
  };
  logOut = {
    loggedOut: 'users.log_out_pending'
  };
  form = {
    email: 'forms.email',
    password: 'forms.password'
  };

}
