import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ApiInterceptor } from './shared/api/api.interceptor';
import { AuthInterceptor } from './shared/api/auth.interceptor';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders[] {
    return [
      {
        ngModule: CoreModule,
        providers: [
          // TODO: Consider if we need this pattern
          //   AlertService,
          //   ToastService,
        ],
      },
    ];
  }
}
