import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@root/src/environments/environment';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { LoginRequest } from '../api/login-request.model';
import { LoginResponse } from '../api/login-response.model';
import { Tokens } from '../models/tokens.model';
import { AlertService } from './alert.service';


@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private loggedUser: string;
  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private alertService: AlertService) {}

  login(loginRequest: LoginRequest): Observable<boolean> {
    return this.http.post<LoginResponse>(`${environment.apiRoot}/v1/Account/Login`, loginRequest)
      .pipe(
        tap(tokens => this.doLoginUser(loginRequest.email, tokens)),
        mapTo(true),
        catchError(error => {

          this.alertService.showApiError(error.error.error[0]);
          return of(false);
        }));
  }

  logout(): void {
    this.doLogoutUser();
  }

  isLoggedIn(): boolean {
    this.isLoggedIn$.next(!!this.getJwtToken());
    return !!this.getJwtToken();
  }

  refreshToken(): Observable<any> {
    return this.http.post<any>(`${environment.apiRoot}/v1/Account/RefreshToken`,  this.getTokens()
    ).pipe(tap((tokens: any) => {
      this.storeJwtToken(tokens.accessToken.token);
    }));
  }

  getJwtToken(): string {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(username: string, tokens: LoginResponse): void {
    this.loggedUser = username;
    this.storeTokens(tokens);
  }

  private doLogoutUser(): void {
    this.loggedUser = null;
    this.removeTokens();
  }

  public getTokens(): Tokens {
    const tokens: Tokens = {
      accessToken: localStorage.getItem(this.JWT_TOKEN),
      refreshToken: localStorage.getItem(this.REFRESH_TOKEN)
    };

    return tokens;
  }

  private storeJwtToken(accessToken: string): void {
    localStorage.setItem(this.JWT_TOKEN, accessToken);
  }

  private storeTokens(tokens: any): void {
    localStorage.setItem(this.JWT_TOKEN, tokens.accessToken.token);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens(): void {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    this.isLoggedIn$.next(false);
  }
}
