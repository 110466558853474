import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { HomeModule } from './modules/home/home.module';
import { PlaceholderModule } from './modules/placeholder/placeholder.module';
import { LogoutComponent } from './modules/users/pages';
import { LoginComponent } from './modules/users/pages/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home',
    component: HomeComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'ui-demo',
    loadChildren: './modules/ui-demo/ui-demo.module#UiDemoModule',
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    HomeModule,
    PlaceholderModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
