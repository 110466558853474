import { Component } from '@angular/core';
import { HomedKeys } from '@modules/home/translation-keys.model';

@Component({
  selector: 'yosite-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  keys: HomedKeys = new HomedKeys();
}
