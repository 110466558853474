import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { proprietaryIcons } from './proprietary-icons';

const pathToIcons = '/assets/img/svg-icons/';
const iconPrefix = 'yo_';

@Component({
  selector: 'yosite-proprietary-icons',
  template: '<ng-content></ng-content>',
})
export class ProprietaryIconsComponent implements OnInit {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    proprietaryIcons.forEach(icon => {
      const fileName = icon.fileName || icon.label;
      const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`${pathToIcons}${fileName}.svg`);
      const registrationLabel = `${iconPrefix}${icon.label}`;
      this.matIconRegistry.addSvgIcon(registrationLabel, safeUrl);
    });
  }

}
