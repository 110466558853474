import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import {
  MatSidenavModule,
  MatMenuModule,
  MatToolbarModule,
  MatListModule,
  MatIconModule,
  MatDialogModule,
  MatButtonModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS
} from '@angular/material';
import * as components from './components';
import * as services from './services';
import { AlertErrorViewComponent } from './components/alert-error/alert-error-view/alert-error-view.component';

import { ConfirmationViewComponent } from './components/confirmation/confirmation-view/confirmation-view.component';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../core.module';
import { LayoutComponent } from './components/layout/layout.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    MatSidenavModule,
    MatMenuModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule
  ],
  declarations: [
    ...components.entities,
    AlertErrorViewComponent,
    ConfirmationViewComponent,
    LayoutComponent,
  ],
  exports: [
    ...components.entities,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
      return ({
        ngModule: SharedModule,
        providers: [
          ...services.entities,
          { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500, horizontalPosition: 'left' } },
        ],
      });
  }

 }
