<div class="login-lift-label">
  <img src="/assets/img/login-screen-platform-lift-label.png">
</div>
<div class="form-column">
  <form (submit)="handleSubmit()" [formGroup]="loginForm">
    <h3 class="text-center interline-space">{{keys.login.title | translate | uppercase}}</h3>
    <mat-form-field appearance="outline" class="mb-0">
      <mat-label>
        {{keys.form.email | translate | titlecase }}
      </mat-label>
      <input matInput formControlName="email">
    </mat-form-field>

    <mat-form-field appearance="outline" class="mb-0" floatLabel="auto">
      <mat-label>
        {{keys.form.password | translate | titlecase }}
      </mat-label>

      <input matInput placeholder="Password" [type]="!showPassword ? 'password' : 'text'" formControlName="password">
      <mat-icon class="visibility" fontSet="material-icons-outlined" color="accent" matSuffix
                (click)="showPassword = !showPassword">
        {{showPassword ? 'visibility_off' : 'visibility'}}
      </mat-icon>
    </mat-form-field>


    <div>
      <button mat-flat-button color="accent" [disabled]="loginDisabled()">
        <mat-spinner [diameter]="20" [strokeWidth]="1" *ngIf="pending"></mat-spinner>
        {{keys.login.loginButton | translate |uppercase}}
      </button>
    </div>
  </form>
</div>
