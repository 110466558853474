import { Injectable } from '@angular/core';
import { Confirmation } from '../models/confirmation.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  private confirmation$: Subject<Confirmation> = new Subject<Confirmation>();

  confirmation() {
    return this.confirmation$.asObservable();
  }

  ask(confirmation: Confirmation): void {
    this.confirmation$.next(confirmation);
  }

}
