import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Alert } from '../alert.model';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alertError$: Subject<Alert> = new Subject<Alert>();

  showError(alert: Alert): void {
    this.alertError$.next(alert);
  }

  showApiError(error: any): void {
    const API_ERROR_TITLE = 'api.error.default';

    if (_.isString(error)) {
      this.showError({
        title: API_ERROR_TITLE,
        message: error
      });
      return;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.error && error.error.MessageCode) {
        return this.showError({
          title: error.statusText,
          message: error.error.MessageCode
        });
      }

      return this.showError({
        title: error.statusText,
        message: error.message
      });
    }

    if (error.MessageCode || error.messageCode) {
      const message = error.MessageCode || error.messageCode;
      this.showError({ message, title: API_ERROR_TITLE });
    }
  }
}
