import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatIconModule, MatCheckboxModule, MatButtonModule, MatProgressSpinnerModule } from '@angular/material';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import * as pages from './pages';
import { CoreModule } from '../../core.module';
import { TranslateModule } from '@root/node_modules/@ngx-translate/core';

@NgModule({
  declarations: [...pages.entities],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    SharedModule,
    TranslateModule,
  ],
  exports: [...pages.entities],
})
export class UsersModule { }
