import { Component, OnDestroy } from '@angular/core';
import { AuthorizationService } from '@root/src/app/shared/services/authorization.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginRequest } from '@root/src/app/shared/api/login-request.model';
import { Router } from '@angular/router';
import { AlertService } from '@root/src/app/shared/services';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UsersKeys } from '@modules/users/translation-keys.model';

@AutoUnsubscribe()
@Component({
  selector: 'yosite-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  keys: UsersKeys = new UsersKeys();
  showPassword = false;
  loginForm = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, Validators.required],
    remember: []
  });
  protected loginSubscription: Subscription;
  pending = false;

  constructor(
    private alertService: AlertService,
    private authorizationService: AuthorizationService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
  }

  ngOnDestroy() {
  }

  handleSubmit() {
    this.pending = true;
    this.loginSubscription = this.authorizationService
      .login(this.loginForm.value as LoginRequest)
      .pipe(
        finalize(() =>
          this.pending = false)
      )
      .subscribe(
        (resp) => {
          this.router.navigateByUrl('/');
        },
        error => {
          this.alertService.showApiError(error);
        }
      );
  }

  loginDisabled(): boolean {
    return this.loginForm.invalid || this.pending;
  }

}
