export const sharedComponentsKeys = {
  navigation: {
    dashboard: 'dashboard.page_heading',
    lifts: 'lifts.title',
    logs: 'logs.title',
    packages: 'navigation.packages',
    users: 'navigation.users',
    demo: 'navigation.ui_demo'
  },
  ok_button: 'forms.button_ok',
  app_title: 'application_title'
};
