<ng-container *ngIf="true || (authorizationService.isLoggedIn$ | async); else notLoggedIn">
  <yosite-layout></yosite-layout>
</ng-container>

<ng-template #notLoggedIn>
  <yosite-login></yosite-login>
</ng-template>

<yosite-alert-error></yosite-alert-error>
<yosite-confirmation></yosite-confirmation>
<yosite-proprietary-icons></yosite-proprietary-icons>
