<svg width="232" height="96" xmlns="http://www.w3.org/2000/svg">
  <g>
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,702.86667) " id="g10">
      <g transform="translate(-32.25,25.5) scale(0.1) " id="g12">
        <g fill=" #b21d23" id="yo-site">
          <path id="Yo" d="m537.913984,4602.530261l114.879,246.05l109.066,0l-72.922,-156.19c-27.914,-52.65 -7.859,-117.96 44.793,-145.88c52.657,-27.91 117.969,-7.86 145.883,44.8c27.91,52.65 7.856,117.96 -44.801,145.88c-9.316,5.5 -27.382,11.11 -38.175,11.85l42.949,92.01c42.023,-9.02 95.379,-47.76 116.969,-84.93c63.11,-95.15 37.14,-223.44 -58.004,-286.55c-95.153,-63.11 -223.45,-37.14 -286.559,58l-104.371,-223.55l-109.066,0l82.523,176.77l-151.078,367.79l106.84,0" fill-rule="nonzero"/>
          <path id="Site" d="m1749.694984,4602.520261l303.43,0c21.72,112.09 -51.53,220.57 -163.62,242.29c-10,2.23 -29.09,4.06 -39.33,3.77l-247.34,0l0,131.1l-98.83,0l0,-131.1l-147.53,0l0,-278.02c-5.49,29.4 -32.18,64.78 -58.94,78.14l-122.66,73.26c-4.79,2.08 -8.45,7.94 -8.21,13.15c0,8.09 6.55,14.64 14.64,14.64l130.03,0l0,98.83l-130.03,0l-0.04,0c-62.67,-0.02 -113.45,-50.84 -113.43,-113.5c-1.84,-38.3 24.45,-82.68 58.93,-99.46l122.65,-73.28c4.79,-2.07 8.45,-7.93 8.22,-13.14c0,-8.09 -6.57,-14.64 -14.65,-14.64l-149.78,0l-40,-98.83l189.82,0c52.81,-0.66 103.24,40.29 113.45,92.1l0,-85.85l98.83,0l0,307.77l48.7,0l0,-307.77l98.83,0l0,307.77l247.34,0c44.23,1.55 89.72,-31.18 102.31,-73.62l-306.18,0c-18.94,-112.59 56.98,-219.22 169.58,-238.15c75.12,-15.95 167.14,24.04 206.74,89.84l-91.67,42.49c-8.75,-10.99 -28.12,-24.52 -41.45,-28.94c-55.5,-21.73 -118.09,5.65 -139.81,61.15" fill-rule="nonzero"/>
          <path id="Off" d="m1446.534984,4981.500261c22.38,-22.43 22.35,-58.76 -0.05,-81.15c-22.42,-22.42 -58.77,-22.42 -81.19,0c-22.42,22.42 -22.42,58.77 0,81.19l13.02,-13.02c-15.23,-15.23 -15.23,-39.91 0,-55.15c15.23,-15.23 39.92,-15.23 55.15,0c15.21,15.2 15.24,39.87 0.04,55.1l13.03,13.03" fill-rule="nonzero"/>
          <path id="path72" d="m1396.714984,4940.950261l18.35,0l0,68.05l-18.35,0l0,-68.05" fill-rule="nonzero"/>
        </g>
        <g fill=" #666" id="text">
          <path id="initialI" d="m1054.774984,4389.270261l12.52,0l0,-49.89l-12.52,0l0,49.89zm6.26,20.54c2.2,0 4.08,-0.79 5.64,-2.38c1.58,-1.59 2.36,-3.52 2.36,-5.78c0,-2.23 -0.77,-4.13 -2.33,-5.7c-1.55,-1.58 -3.42,-2.37 -5.58,-2.37c-2.22,0 -4.12,0.8 -5.69,2.41c-1.57,1.6 -2.36,3.55 -2.36,5.84c0,2.21 0.78,4.08 2.33,5.64c1.56,1.57 3.43,2.34 5.63,2.34" fill-rule="nonzero"/>
          <path id="path18" d="m1083.364984,4389.270261l12.52,0l0,-5.11c2.83,2.39 5.41,4.06 7.71,4.99c2.31,0.93 4.67,1.41 7.07,1.41c4.95,0 9.15,-1.74 12.6,-5.19c2.9,-2.93 4.35,-7.27 4.35,-13.02l0,-32.97l-12.38,0l0,21.85c0,5.95 -0.27,9.9 -0.8,11.86c-0.54,1.95 -1.47,3.45 -2.8,4.47c-1.32,1.02 -2.96,1.53 -4.91,1.53c-2.53,0 -4.7,-0.85 -6.52,-2.54c-1.82,-1.69 -3.07,-4.04 -3.78,-7.03c-0.36,-1.56 -0.54,-4.93 -0.54,-10.13l0,-20.01l-12.52,0l0,49.89" fill-rule="nonzero"/>
          <path id="path20" d="m1145.924984,4407.660261l12.52,0l0,-18.39l7.43,0l0,-10.78l-7.43,0l0,-39.11l-12.52,0l0,39.11l-6.42,0l0,10.78l6.42,0l0,18.39" fill-rule="nonzero"/>
          <path id="path22" d="m1214.724984,4370.560261c-0.83,2.78 -2.46,5.05 -4.9,6.79c-2.43,1.74 -5.26,2.61 -8.47,2.61c-3.49,0 -6.55,-0.98 -9.18,-2.93c-1.66,-1.23 -3.19,-3.38 -4.6,-6.47l27.15,0zm12.52,-9.86l-40.22,0c0.58,-3.54 2.13,-6.36 4.65,-8.46c2.53,-2.09 5.75,-3.14 9.66,-3.14c4.67,0 8.69,1.64 12.06,4.91l10.54,-4.95c-2.63,-3.73 -5.77,-6.49 -9.44,-8.28c-3.67,-1.79 -8.03,-2.68 -13.07,-2.68c-7.83,0 -14.2,2.47 -19.12,7.4c-4.92,4.94 -7.38,11.12 -7.38,18.55c0,7.61 2.45,13.93 7.35,18.96c4.91,5.03 11.06,7.55 18.46,7.55c7.86,0 14.24,-2.52 19.17,-7.55c4.92,-5.03 7.38,-11.67 7.38,-19.92l-0.04,-2.39" fill-rule="nonzero"/>
          <path id="path24" d="m1265.964984,4378.860261c-3.95,0 -7.21,-1.32 -9.77,-3.97c-2.57,-2.64 -3.86,-5.96 -3.86,-9.97c0,-4.16 1.32,-7.56 3.95,-10.22c2.63,-2.66 5.93,-3.99 9.91,-3.99c3.88,0 7.08,1.3 9.6,3.9c2.52,2.59 3.78,6 3.78,10.22c0,4.15 -1.27,7.53 -3.8,10.13c-2.54,2.6 -5.81,3.9 -9.81,3.9zm12.97,10.41l12.48,0l0,-42.73c0,-8.44 -1.7,-14.65 -5.1,-18.63c-4.55,-5.37 -11.41,-8.07 -20.58,-8.07c-4.9,0 -9.01,0.62 -12.34,1.84c-3.33,1.23 -6.15,3.02 -8.44,5.39c-2.29,2.37 -3.98,5.25 -5.09,8.65l13.81,0c1.22,-1.41 2.79,-2.48 4.72,-3.19c1.93,-0.72 4.2,-1.08 6.83,-1.08c3.36,0 6.07,0.52 8.12,1.56c2.04,1.04 3.49,2.38 4.33,4.03c0.84,1.65 1.26,4.5 1.26,8.53c-2.2,-2.2 -4.51,-3.78 -6.92,-4.74c-2.42,-0.97 -5.16,-1.45 -8.21,-1.45c-6.7,0 -12.35,2.41 -16.97,7.25c-4.61,4.83 -6.92,10.94 -6.92,18.34c0,7.91 2.44,14.27 7.33,19.07c4.44,4.35 9.73,6.52 15.87,6.52c2.88,0 5.59,-0.53 8.14,-1.59c2.55,-1.06 5.11,-2.79 7.68,-5.2l0,5.5" fill-rule="nonzero"/>
          <path id="path26" d="m1305.824984,4389.270261l10.73,0l0,-6.28c1.16,2.48 2.7,4.35 4.63,5.64c1.92,1.28 4.03,1.93 6.33,1.93c1.62,0 3.31,-0.44 5.09,-1.29l-3.9,-10.78c-1.47,0.74 -2.68,1.11 -3.62,1.11c-1.93,0 -3.56,-1.19 -4.89,-3.58c-1.32,-2.39 -1.99,-7.06 -1.99,-14.03l0.05,-2.43l0,-20.18l-12.43,0l0,49.89" fill-rule="nonzero"/>
          <path id="path28" d="m1367.154984,4379.000261c-3.87,0 -7.09,-1.37 -9.65,-4.11c-2.55,-2.73 -3.83,-6.24 -3.83,-10.52c0,-4.31 1.29,-7.85 3.9,-10.64c2.6,-2.78 5.81,-4.17 9.62,-4.17c3.93,0 7.19,1.37 9.78,4.1c2.59,2.74 3.88,6.33 3.88,10.75c0,4.35 -1.29,7.86 -3.88,10.55c-2.59,2.69 -5.86,4.04 -9.82,4.04zm13.16,10.27l12.52,0l0,-49.89l-12.52,0l0,5.28c-2.45,-2.33 -4.9,-4 -7.36,-5.02c-2.45,-1.03 -5.12,-1.54 -7.99,-1.54c-6.44,0 -12.01,2.5 -16.71,7.49c-4.71,5 -7.06,11.22 -7.06,18.64c0,7.71 2.28,14.02 6.83,18.94c4.55,4.92 10.07,7.39 16.58,7.39c2.99,0 5.79,-0.57 8.42,-1.7c2.63,-1.13 5.05,-2.83 7.29,-5.09l0,5.5" fill-rule="nonzero"/>
          <path id="path30" d="m1411.694984,4407.660261l12.52,0l0,-18.39l7.42,0l0,-10.78l-7.42,0l0,-39.11l-12.52,0l0,39.11l-6.42,0l0,10.78l6.42,0l0,18.39" fill-rule="nonzero"/>
          <path id="path32" d="m1480.494984,4370.560261c-0.83,2.78 -2.46,5.05 -4.9,6.79c-2.43,1.74 -5.25,2.61 -8.47,2.61c-3.49,0 -6.55,-0.98 -9.18,-2.93c-1.66,-1.23 -3.19,-3.38 -4.6,-6.47l27.15,0zm12.52,-9.86l-40.22,0c0.58,-3.54 2.14,-6.36 4.66,-8.46c2.52,-2.09 5.74,-3.14 9.65,-3.14c4.68,0 8.69,1.64 12.06,4.91l10.55,-4.95c-2.64,-3.73 -5.78,-6.49 -9.45,-8.28c-3.67,-1.79 -8.02,-2.68 -13.07,-2.68c-7.83,0 -14.2,2.47 -19.12,7.4c-4.92,4.94 -7.38,11.12 -7.38,18.55c0,7.61 2.45,13.93 7.36,18.96c4.9,5.03 11.06,7.55 18.45,7.55c7.86,0 14.25,-2.52 19.17,-7.55c4.92,-5.03 7.38,-11.67 7.38,-19.92l-0.04,-2.39" fill-rule="nonzero"/>
          <path id="path34" d="m1531.504984,4379.000261c-3.87,0 -7.09,-1.37 -9.65,-4.11c-2.55,-2.73 -3.83,-6.24 -3.83,-10.52c0,-4.31 1.3,-7.85 3.9,-10.64c2.61,-2.78 5.81,-4.17 9.62,-4.17c3.93,0 7.19,1.37 9.78,4.1c2.59,2.74 3.88,6.33 3.88,10.75c0,4.35 -1.29,7.86 -3.88,10.55c-2.59,2.69 -5.86,4.04 -9.82,4.04zm13.16,29.53l12.52,0l0,-69.15l-12.52,0l0,5.28c-2.45,-2.33 -4.9,-4 -7.36,-5.02c-2.45,-1.03 -5.12,-1.54 -7.98,-1.54c-6.45,0 -12.02,2.5 -16.72,7.49c-4.71,5 -7.06,11.22 -7.06,18.64c0,7.71 2.28,14.02 6.83,18.94c4.55,4.92 10.08,7.39 16.58,7.39c2.99,0 5.79,-0.57 8.42,-1.7c2.63,-1.13 5.06,-2.83 7.29,-5.09l0,24.76" fill-rule="nonzero"/>
          <path id="path36" d="m1636.034984,4382.300261l-7.75,-7.75c-3.15,3.12 -6.01,4.68 -8.57,4.68c-1.41,0 -2.51,-0.3 -3.3,-0.89c-0.8,-0.61 -1.2,-1.34 -1.2,-2.23c0,-0.67 0.25,-1.29 0.76,-1.86c0.5,-0.56 1.75,-1.34 3.74,-2.31l4.58,-2.3c4.83,-2.38 8.15,-4.81 9.95,-7.28c1.8,-2.48 2.71,-5.39 2.71,-8.72c0,-4.43 -1.63,-8.13 -4.89,-11.1c-3.25,-2.96 -7.62,-4.44 -13.09,-4.44c-7.27,0 -13.08,2.84 -17.42,8.53l7.7,8.39c1.47,-1.71 3.19,-3.1 5.16,-4.15c1.97,-1.06 3.72,-1.58 5.25,-1.58c1.65,0 2.98,0.39 3.99,1.19c1.01,0.79 1.51,1.71 1.51,2.75c0,1.92 -1.82,3.8 -5.45,5.64l-4.22,2.11c-8.07,4.06 -12.11,9.15 -12.11,15.27c0,3.94 1.52,7.31 4.56,10.11c3.05,2.8 6.94,4.2 11.67,4.2c3.25,0 6.29,-0.72 9.15,-2.13c2.86,-1.43 5.28,-3.47 7.27,-6.13" fill-rule="nonzero"/>
          <path id="path38" d="m1675.144984,4378.770261c-3.89,0 -7.13,-1.35 -9.72,-4.05c-2.58,-2.71 -3.87,-6.17 -3.87,-10.39c0,-4.34 1.27,-7.86 3.83,-10.55c2.56,-2.69 5.8,-4.04 9.72,-4.04c3.91,0 7.17,1.37 9.78,4.08c2.6,2.72 3.9,6.22 3.9,10.51c0,4.28 -1.28,7.75 -3.83,10.43c-2.56,2.67 -5.83,4.01 -9.81,4.01zm-0.2,11.79c4.7,0 9.13,-1.18 13.27,-3.54c4.14,-2.35 7.37,-5.54 9.7,-9.58c2.32,-4.03 3.48,-8.39 3.48,-13.07c0,-4.71 -1.17,-9.11 -3.51,-13.21c-2.33,-4.09 -5.52,-7.29 -9.56,-9.6c-4.03,-2.31 -8.48,-3.46 -13.34,-3.46c-7.15,0 -13.26,2.54 -18.32,7.63c-5.06,5.09 -7.59,11.27 -7.59,18.55c0,7.8 2.86,14.29 8.58,19.49c5.01,4.52 10.77,6.79 17.29,6.79" fill-rule="nonzero"/>
          <path id="path40" d="m1714.944984,4408.530261l12.51,0l0,-69.15l-12.51,0l0,69.15" fill-rule="nonzero"/>
          <path id="path42" d="m1743.484984,4389.270261l12.65,0l0,-24.03c0,-4.67 0.32,-7.92 0.97,-9.74c0.64,-1.82 1.68,-3.23 3.1,-4.24c1.42,-1.01 3.18,-1.52 5.26,-1.52c2.09,0 3.86,0.5 5.32,1.5c1.45,0.99 2.53,2.45 3.23,4.37c0.52,1.44 0.79,4.51 0.79,9.22l0,24.44l12.51,0l0,-21.14c0,-8.71 -0.69,-14.67 -2.06,-17.88c-1.68,-3.92 -4.16,-6.92 -7.43,-9.01c-3.27,-2.09 -7.43,-3.14 -12.47,-3.14c-5.47,0 -9.9,1.22 -13.28,3.67c-3.37,2.44 -5.75,5.85 -7.12,10.22c-0.98,3.03 -1.47,8.53 -1.47,16.51l0,20.77" fill-rule="nonzero"/>
          <path id="path44" d="m1806.084984,4407.660261l12.52,0l0,-18.39l7.43,0l0,-10.78l-7.43,0l0,-39.11l-12.52,0l0,39.11l-6.42,0l0,10.78l6.42,0l0,18.39" fill-rule="nonzero"/>
          <path id="path46" d="m1836.044984,4389.270261l12.52,0l0,-49.89l-12.52,0l0,49.89zm6.26,20.54c2.2,0 4.08,-0.79 5.65,-2.38c1.57,-1.59 2.36,-3.52 2.36,-5.78c0,-2.23 -0.78,-4.13 -2.34,-5.7c-1.55,-1.58 -3.41,-2.37 -5.57,-2.37c-2.23,0 -4.13,0.8 -5.7,2.41c-1.57,1.6 -2.35,3.55 -2.35,5.84c0,2.21 0.78,4.08 2.33,5.64c1.56,1.57 3.43,2.34 5.62,2.34" fill-rule="nonzero"/>
          <path id="path48" d="m1888.274984,4378.770261c-3.89,0 -7.13,-1.35 -9.72,-4.05c-2.58,-2.71 -3.87,-6.17 -3.87,-10.39c0,-4.34 1.27,-7.86 3.83,-10.55c2.56,-2.69 5.8,-4.04 9.72,-4.04c3.91,0 7.17,1.37 9.78,4.08c2.6,2.72 3.9,6.22 3.9,10.51c0,4.28 -1.28,7.75 -3.84,10.43c-2.55,2.67 -5.82,4.01 -9.8,4.01zm-0.2,11.79c4.7,0 9.13,-1.18 13.27,-3.54c4.14,-2.35 7.37,-5.54 9.69,-9.58c2.33,-4.03 3.49,-8.39 3.49,-13.07c0,-4.71 -1.17,-9.11 -3.51,-13.21c-2.33,-4.09 -5.52,-7.29 -9.56,-9.6c-4.03,-2.31 -8.48,-3.46 -13.34,-3.46c-7.15,0 -13.26,2.54 -18.32,7.63c-5.06,5.09 -7.59,11.27 -7.59,18.55c0,7.8 2.86,14.29 8.58,19.49c5.01,4.52 10.77,6.79 17.29,6.79" fill-rule="nonzero"/>
          <path id="path50" d="m1929.444984,4389.270261l12.52,0l0,-5.11c2.84,2.39 5.41,4.06 7.72,4.99c2.3,0.93 4.66,1.41 7.07,1.41c4.94,0 9.14,-1.74 12.59,-5.19c2.9,-2.93 4.35,-7.27 4.35,-13.02l0,-32.97l-12.38,0l0,21.85c0,5.95 -0.27,9.9 -0.8,11.86c-0.54,1.95 -1.46,3.45 -2.79,4.47c-1.33,1.02 -2.97,1.53 -4.92,1.53c-2.53,0 -4.7,-0.85 -6.52,-2.54c-1.81,-1.69 -3.07,-4.04 -3.77,-7.03c-0.37,-1.56 -0.55,-4.93 -0.55,-10.13l0,-20.01l-12.52,0l0,49.89" fill-rule="nonzero"/>
          <path id="path52" d="m2021.034984,4382.300261l-7.75,-7.75c-3.15,3.12 -6.01,4.68 -8.57,4.68c-1.41,0 -2.51,-0.3 -3.3,-0.89c-0.8,-0.61 -1.2,-1.34 -1.2,-2.23c0,-0.67 0.25,-1.29 0.76,-1.86c0.5,-0.56 1.75,-1.34 3.74,-2.31l4.58,-2.3c4.83,-2.38 8.15,-4.81 9.95,-7.28c1.8,-2.48 2.71,-5.39 2.71,-8.72c0,-4.43 -1.63,-8.13 -4.89,-11.1c-3.25,-2.96 -7.62,-4.44 -13.09,-4.44c-7.27,0 -13.08,2.84 -17.42,8.53l7.7,8.39c1.47,-1.71 3.19,-3.1 5.16,-4.15c1.97,-1.06 3.72,-1.58 5.25,-1.58c1.65,0 2.98,0.39 3.99,1.19c1.01,0.79 1.51,1.71 1.51,2.75c0,1.92 -1.82,3.8 -5.45,5.64l-4.22,2.11c-8.07,4.06 -12.11,9.15 -12.11,15.27c0,3.94 1.52,7.31 4.56,10.11c3.05,2.8 6.94,4.2 11.67,4.2c3.25,0 6.29,-0.72 9.15,-2.13c2.86,-1.43 5.28,-3.47 7.27,-6.13" fill-rule="nonzero"/>
        </g>
      </g>
    </g>
  </g>
</svg>
