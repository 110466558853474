import { Component, Inject } from '@angular/core';
import { Alert } from '../../../alert.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Confirmation } from '../../../models/confirmation.model';
import { ActionButton } from '../../../models/action-button.model';

@Component({
  selector: 'yosite-confirmation-view',
  styleUrls: ['./confirmation-view.component.scss'],
  templateUrl: './confirmation-view.component.html',
})
export class ConfirmationViewComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Confirmation
  ) { }

  buttonClick(button: ActionButton) {
    if (button.callback) {
      button.callback();
    } else {
      this.dialogRef.close();
    }
  }
}
