import { Component, Inject } from '@angular/core';
import { Alert } from '../../../alert.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { sharedComponentsKeys } from '@shared/components/translation-keys.model';

@Component({
  selector: 'yosite-alert-error-view',
  styleUrls: ['./alert-error-view.component.scss'],
  templateUrl: './alert-error-view.component.html',
})
export class AlertErrorViewComponent {
  keys = sharedComponentsKeys;

  constructor(
    public dialogRef: MatDialogRef<AlertErrorViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Alert
  ) {
  }

}
