import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@root/src/environments/environment.prod';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqUrl = req.url;
    const currentLanguage = this.translateService.currentLang;

    if (reqUrl.includes(environment.apiRoot)) {
      const modifiedReq: HttpRequest<any> = req.clone({
        url: this.normalizeUrl(req.url),
        headers: req.headers.set('Content-Language', currentLanguage)
      });
      return next.handle(modifiedReq);
    } else {
      return next.handle(req);
    }
  }

  private normalizeUrl(url: string): string {
    return environment.apiRoot + `/${url}`.replace(/\/{2,}/g, '/');
  }
}
