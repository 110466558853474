export const firebaseConfig: any = {
  apiKey: 'AIzaSyApxt3AutsIGhyV_tJU-dgOPil2x9bQSys',
  authDomain: 'yo-site-biz.firebaseapp.com',
  databaseURL: 'https://yo-site-biz.firebaseio.com',
  projectId: 'yo-site-biz',
  storageBucket: 'yo-site-biz.appspot.com',
  messagingSenderId: '642374080569',
  appId: '1:642374080569:web:a5b93795810033f85a95e8',
  measurementId: 'G-G71M5E1N1J'
};
